

 .blog-right-bar .tag-widget {
    margin-top: 60px;
}

  .blog-right-bar .tag-widget h3 {
      font-size: 24px;
      margin-bottom: 15px;
  }

  .blog-right-bar .tag-widget ul {
      overflow: hidden;
  }

  .blog-right-bar .tag-widget ul li {
      float: left;
      margin: 0 8px 8px 0;
  }

  .blog-right-bar .tag-widget ul li a {
      background: #f5f5f5;
      font-size: 14px;
      display: inline-block;
      padding: 9px 25px 10px;
      color: #333;
      transition: all .3s;
  }

  .blog-right-bar .tag-widget ul li a:hover {
      background: #F9B233;
      color: #fff;
  }

  .instagram ul {
      flex-wrap: wrap;
      margin: 0px -3px;
  }

  .instagram ul li {
      -ms-flex: 0 0 33.33%;
      flex: 0 0 33.33%;
      max-width: 33.33%;
      margin-bottom: 5px;
      padding: 0px 3px;
  }

  .instagram ul li img {
      width: 100%;
  }

  .instagram {
      margin-top: 60px;
  }

  .instagram h3 {
      font-size: 24px;
      margin-bottom: 15px;
  }

  .blog-right-bar {
      padding-left: 30px;
  }

  .col-g {
      padding-right: 0;
  }

 .search-widget input {
    height: 50px;
    font-size: 17px;
    padding: 6px 50px 6px 20px;
    border: 0;
    box-shadow: none;
    -webkit-transition: all .3s;
    transition: all .3s;
    border: 1px solid rgba(208,161,94,.28);
    color: #333;
    border-radius: 0;
}
.search-widget input:focus{
    outline: none;
    box-shadow: none;
    border: 1px solid rgba(208,161,94,.28);
}
.search-widget form button {
    background: #F9B233;
    font-size: 20px;
    color: #fff;
    border: 0;
    outline: 0;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 10px 15px;
    cursor: pointer;
}

.case-stadies-details-area .practice-section, .practice-details-area .practice-section {
    padding: 10px 30px 40px;
}
.practice-catagory-item {
    background: #f5f5f5;
    margin-top: 60px;
}

.practice-details-area.case-stadies-details-area .img-holder {
    float: left;
    padding-right: 20px;
    margin-top: 10px;
}

.practice-section.resent-section h3 {
    margin-bottom: 30px;
    margin-top: 20px;
}
.practice-section.resent-section p{
    margin-top: -2px;
}
