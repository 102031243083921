.wpo-team-area,.wpo-team-area-2{
    padding: 100px 0 70px;
    background: #fff;
  }
  .wpo-team-img {
      position: relative;
      overflow: hidden;
  }


  .wpo-team-img:before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: #F9B233;
      opacity: 0;
      transition: all .3s;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
  }
  .wpo-team-single {
      margin-bottom: 30px;
  }

  .wpo-team-single:hover .wpo-team-img:before {
      opacity: .5;
  }

  .wpo-team-img img {
      transform: scale(1);
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      transition: all .3s;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
  }

  .wpo-team-single:hover .wpo-team-img img {
      transform: scale(1.2);
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
  }

  .social-1st {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      z-index: 2;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      opacity: 0;
  }



  .wpo-team-single:hover .thumb-content span {
      opacity: 1;
  }

  .wpo-team-single:hover .social-1st {
      opacity: 1;
      transition: all .3s;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
  }

  .wpo-team-single:hover .social-1st li:nth-child(2) a {
      margin-top: 50px;
  }

  .wpo-team-single:hover .social-1st li:nth-child(3) a {
      margin-top: 100px;
  }

  .social-1st ul {
      text-align: center;
      justify-content: center;
      display: flex;
  }

  .social-1st li a {
      display: block;
      height: 45px;
      width: 45px;
      background: #fff;
      color: #333;
      line-height: 45px;
      border-radius: 50%;
  }
  .social-1st li a:hover{
    background:#F9B233;
    color: #fff;
  }

  .social-1st li:nth-child(2) a {
      margin-top: 0px;
      transition: all .3s;
  }

  .social-1st li:nth-child(3) a {
      margin-top: 0px;
      transition: all .3s;
  }

  .wpo-team-img {
      position: relative;
  }

  .team-wrap {
      margin-bottom: 30px;
  }
  .wpo-team-content{
    text-align: center;
    padding: 30px 0;
    background: #f5f5f5;
  }
  .wpo-team-content h4{
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    transition: all .3s;
  }
  .wpo-team-content h4:hover{
    color:#F9B233;;
  }
  .wpo-team-content span{
    font-size: 14px;
    font-weight: 400;
    color:#666;
  }

  .wpo-team-area-2{
    background: #f5f5f5;
  }
  .wpo-team-area-2 .wpo-team-content {
      background: #fff;
  }
