.wpo-counter-area,.counter-style-2{
    padding: 135px 0 0;
    background: url('../../images/counter/2.jpg') no-repeat center top / cover;
    position: relative;
    background-attachment: fixed;
  }
  .wpo-counter-area:before,
  .counter-style-2:before{
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      background: rgba(20,36,64,.8);
  }
  .wpo-counter-content {
      background: #F9B233;
      padding: 44px 70px 94px;
      padding-right: 80px;
      margin-bottom: -70px;
      max-height: 450px;
      position: relative;
  }
  .wpo-counter-content p{
    color:#fff;
  }
  .wpo-counter-content h2{
    font-size: 30px;
    font-weight: 700;
    color:#fff;
    margin-bottom: 50px;
    line-height: 54px;
  }
  .btn-style.btn-style-3 a{
    background: #fff;
    color:#333;
    margin-top: 34px;
    font-weight: 700;
  }

  .wpo-counter-area .wpo-counter-grids .grid,
  .counter-style-2 .wpo-counter-grids .grid {
      width: 48%;
      float: left;
      padding: 0px 0px 35px;
      margin-right: 5px;
      margin-bottom: 5px;
  }
  .wpo-counter-area .wpo-counter-grids .grid p,
  .counter-style-2 .wpo-counter-grids .grid p {
      font-size: 16px;
      font-weight: normal;
      color: #fff;
      margin-top: 20px;
  }
  .wpo-counter-area .wpo-counter-grids,
  .counter-style-2 .wpo-counter-grids {
      overflow: hidden;
      position: relative;
      padding-left: 50px;
  }
  @media (max-width: 991px) {
    .wpo-counter-area .wpo-counter-grids,
    .counter-style-2 .wpo-counter-grids {
        padding: 100px 0px 35px;
        text-align: center;
    }
  }

  .wpo-counter-area .odometer.odometer-auto-theme, .counter-style-2 .odometer.odometer-theme-default {
      line-height: 0.8em;
  }
  .wpo-counter-area .wpo-counter-grids .grid h2,
  .counter-style-2 .wpo-counter-grids .grid h2 {
      font-size: 36px;
      font-weight: bold;
      color: #fff;
      margin: 0;
      line-height: 70px;
      padding-bottom: 10px;
      position: relative;
  }
  .wpo-counter-area .wpo-counter-grids .grid h2:before,
  .counter-style-2 .wpo-counter-grids .grid h2:before{
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 60px;
      height: 3px;
      background: #F9B233;
  }


.counter-style-2 .wpo-counter-content {
  margin-bottom:0px;
}
.counter-style-2 {
     padding: 0;
}
.counter-style-2 .wpo-counter-grids {
    padding-top: 75px;
}
