 .wpo-section-icon.section2{
    position: absolute;
    left: -5px;
    top: 30px;
    font-family: Flaticon;
    font-size: 42px;
    fill: #F9B233;
  }

  .wpo-section-icon i.fi, .wpo-section-icon svg.fi{
      background: none;
      padding: 47px 30px 30px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      transition: all .5s;
  }

  .service-content h3:before,
.service-content2 h3:before,
.service-content3 h3:before,
.service-content4 h3:before,
.service-content5 h3:before,
.service-content6 h3:before{
  content: none;
}
