.service-style-3{
    background: url('../../images/service/bg.jpg') no-repeat center center / cover;
    position: relative;
}
.service-style-3:before{
      content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 36, 64, .8);
}
.service-style-3 .wpo-section-title h2 {
    color: #fff;
}
.service-style-3 .wpo-section-title span {
    position: relative;
}
.service-style-3 .wpo-service-item{
  overflow: hidden;
  margin-bottom: 30px;
}

.service-style-3 .wpo-service-item .wpo-service-icon{
  float: left;
  width: 100px;
  height: 140px;
  line-height: 140px;
  text-align: center;
  color:#ddd;
  background: rgba(52,204,255,.5);
}
.service-style-3 .wpo-service-item .service-text {
    overflow: hidden;
    background: rgba(255,255,255,.25);
    padding: 25px 0 18px;
    padding-left: 20px;
    max-height: 140px;
}
.service-style-3 .wpo-service-item .service-text h3{
  font-size: 20px;
  color:#fffefe;
  transition: all .3s;
}
.service-style-3 .wpo-service-item .service-text h3:hover{
  color:#F9B233;
}
.service-style-3 .wpo-service-item .service-text p{
  color:#ddd;
}
