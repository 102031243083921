.wpo-service-area,.wpo-service-s2,.service-style-3{
  padding: 100px 0 70px;
  background: url('../../images/counter/background.jpg') no-repeat center top / cover;
    position: relative;
    background-attachment: fixed;
}
.wpo-section-title span{
    font-size: 16px;
    color: #F9B233;
}
.wpo-section-title h2{
    font-size: 36px;
    font-weight: 700;
    color: #333;
    padding-bottom: 20px;
    margin-bottom: 60px;
    position: relative;
}
.wpo-section-title h2:before {
    content: "";
    position: absolute;
    left: 47%;
    bottom: 0;
    width: 60px;
    height: 3px;
    background: #F9B233;
}
.wpo-service-single{
  overflow: hidden;
}
.wpo-service-single{
  position: relative;
  overflow: hidden;
  transition: all .3s;
  margin-bottom: 30px;
}

.service-content,
.service-content2,
.service-content3,
.service-content4,
.service-content5,
.service-content6{
    padding: 29px 40px 20px;
    position: absolute;
    left: 0;
    top: 68%;
    width: 100%;
    height: 100%;
    padding-left: 100px;
    background: #142440;
    transition: all .3s;
    opacity:.8;
}

.wpo-service-single:hover .service-content,
.wpo-service-single:hover .service-content2,
.wpo-service-single:hover .service-content3,
.wpo-service-single:hover .service-content4,
.wpo-service-single:hover .service-content5,
.wpo-service-single:hover .service-content6{
 top: 0;
 display: flex;
 justify-content: center;
 flex-direction: column;
 width: 100%;
 height: 100%;
}

.wpo-service-single:before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height:100%;
  background-color: rgba(20, 36, 64, 0.2);
}
.service-content h3,
.service-content2 h3,
.service-content3 h3,
.service-content4 h3,
.service-content5 h3,
.service-content6 h3{
  font-size:20px;
  font-family: 'Kelson', sans-serif;
  position: relative;
  color:#fff;
}
.service-content h3:before,
.service-content2 h3:before,
.service-content3 h3:before,
.service-content4 h3:before,
.service-content5 h3:before,
.service-content6 h3:before{
    content: "\f106";
    position: absolute;
    left: -78px;
    top: -15px;
    font-family: Flaticon;
    font-size: 42px;
    color: #F9B233;
}
.service-content2 h3:before {
    content: "\f107";
    margin-top: 5px;
}
.service-content3 h3:before {
    content: "\f100";
    transform:rotate(45deg);
}
.service-content4 h3:before {
    content: "\f108";
}
.service-content5 h3:before {
    content: "\f10a";
}
.service-content6 h3:before {
    content: "\f10b";
}
.service-content p,
.service-content2 p,
.service-content3 p,
.service-content4 p,
.service-content5 p,
.service-content6 p{
  padding: 15px 0 0px;
  color:#fff;
  line-height: 30px;
}
.service-content a,
.service-content2 a,
.service-content3 a,
.service-content4 a,
.service-content5 a,
.service-content6 a{
  color:#F9B233;
}

.wpo-service-area .wpo-service-item .wpo-service-single:before {
  content: none;
}
.wpo-service-s2  .wpo-service-item .wpo-service-single .wpo-service-icon{
    background-color: rgba(52, 203, 255, 0.2);
    width: 90px;
    height: 90px;
    line-height: 90px;
    text-align: center;
    border-radius: 50%;
    position: relative;
    margin: auto;
    margin-bottom: 25px;
}
.wpo-service-s2 .wpo-service-item .wpo-service-single .wpo-service-icon:before{
    content: "";
    background-color: rgba(52, 203, 255, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;
    left: -5px;
    top: -5px;
    border-radius: 50%;
}
.wpo-service-s2 .wpo-service-item .wpo-service-single .wpo-service-icon i,
.service-style-3 .wpo-service-item .wpo-service-single .wpo-service-icon i {
    color: #142440;
}
.wpo-service-s2 .wpo-service-item .wpo-service-single h2,
.service-style-3 .wpo-service-item .wpo-service-single h2 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
}
.wpo-service-s2 .wpo-service-item .wpo-service-single p,
.service-style-3 .wpo-service-item .wpo-service-single p {
    line-height: 30px;
    color: #5d5b5b;
    margin-bottom: 0;
}


.wpo-service-s2 .wpo-service-item .wpo-service-single{
    background: #fff;
    text-align: center;
    padding: 40px 33px;
}
.wpo-service-s2 .wpo-service-single:before{
  display: none;
}
