.wpo-testimonial-area{
  background: url('../../images/testimonial/1.jpg') no-repeat center top / cover;
  padding: 100px 0;
  position: relative;
}
.wpo-testimonial-area:before{
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(20,36,64,.7);
}
.wpo-testimonial-img {
  width: 20%;
  margin:auto;
  z-index: 99;
  position: relative;
  background:  #fff;
  border-radius: 50%;
  bottom: -50px;
}
.wpo-testimonial-img img{
  border-radius: 50%;
  border:5px solid #bbb;
}
.wpo-testimonial-item{
  text-align: center;
  background: #fff;
  padding:70px 30px 60px;
  position: relative;
  max-width: 600px;
  margin: auto;
  position: relative;
}

.test-c {
    background: #fff;
    z-index: 99;
    width: 520px;
    height: 50px;
    margin-right: 30px;
    position: relative;
    opacity: .5;
    top: -40px;
    left: 2%;
}
.test-b {
    background: #fff;
    z-index: -15;
    width: 489px;
    height: 50px;
    margin-right: 30px;
    position: relative;
    opacity: .3;
    top: -82px;
    left: 5%;
}
.wpo-testimonial-item p {
    padding-top: 50px;
    position: relative;
    font-size: 18px;
}
.wpo-testimonial-item p:before{
    position: absolute;
    left: 47%;
    top:0;
    content: "\f10d";
    font-family: 'FontAwesome';
    color: #999;
    font-size: 30px;
}
.wpo-testimonial-item h4{
  font-weight: 500;
  font-size: 18px;
  color:#F9B233;
  padding-top: 10px;
}
.wpo-testimonial-item span{
  font-size: 14px;
  color:#666;
}

.wpo-testimonial-active .owl-nav div.owl-next {
    right: -70px;
    left: auto;
}

.wpo-testimonial-active .owl-nav div {
    position: absolute;
    left: -70px;
    top: 50%;
    text-align: center;
    color:#fff;
    font-size: 24px;
}
